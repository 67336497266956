<template>
    <v-container class="mt-0">
            <v-card 
                class="mx-auto pt-2 pl-6 pr-6 overflow-auto"
                :width="widthPage"
                height="70vh"
                rounded="24"
                elevation="24"
                style="text-align: center"
                title="Datenschutzbestimmungen"
                >
                <v-card-text>
                    Die auf der privaten Seite legendenquiz.de abrufbaren Inhalte dienen ausschließlich der allgemeinen Information. Für deren Richtigkeit und Wiedergabe wird keine Gewähr übernommen. Die Haftung für den Inhalt der abrufbaren Informationen wird ausgeschlossen. Der Diensteanbieter übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Diensteanbieter, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen. Der Diensteanbieter behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
                    <p/><br>
                    Haftung für Inhalte
                    <p/>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                    Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    <p/><br>
                    Haftung für Links
                    <p/>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                    <p/><br>
                    Urheberrecht
                    <p/>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                    <p/><br>
                    Quellenangabe: eRecht24
                    <p/><br>
                    1. Datenschutz auf einen Blick
                    <p/>
                    a. Allgemeine Hinweise
                    <p/>
                    Die im Folgenden aufgeführten Punkte geben Ihnen einen einfachen Überblick, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Webseite besuchen.
                    Für die im Text verwendeten Begrifflichkeiten wie z.B. „personenbezogene Daten“ oder deren „Verarbeitung“ wird auf die Definitionen in Art. 4 der Datenschutzgrundverordnung (DSGVO) verwiesen.
                    Personenbezogene Daten sind demnach alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie dieser Datenschutzerklärung.
                    <p/><br>
                    2. Datenerfassung auf unserer Webseite
                    <p/>
                    a. Wer ist verantwortlich für die Datenerfassung auf dieser Webseite?
                    <p/>
                    Die Datenverarbeitung auf dieser Webseite erfolgt durch den Betreiber. Die Kontaktdaten können Sie dem Impressum dieser Webseite entnehmen.
                    <p/><br>
                    b. Wie erfassen wir Ihre Daten?
                    <p/>
                    Es gibt zwei verschiedene Arten wie wir Ihre Daten erheben. Zum einen dadurch, dass Sie uns diese mitteilen – z.B. über das Kontaktformular.
                    Andere Daten werden automatisch durch die IT-Systeme erfasst sobald Sie die Webseite aufrufen. Dabei geht es vor allem und technische Daten (z.B. Uhrzeit des Seitenaufrufs, Browser oder Betriebssystem).
                    <p/><br>
                    c. Wofür nutzen wir Ihre Daten?
                    <p/>
                    Wir erheben diese Daten, um eine fehlerfreie Bereitstellung der Webseite und deren Funktionalität zu gewährleisten. Ein Teil der Daten kann zur Analyse Ihres Nutzerverhaltens verwendet werden. Weitere Informationen dazu finden Sie in den folgenden Punkten.
                    <p/><br>
                    d. Welche Rechte haben Sie bezüglich Ihrer Daten?
                    <p/>
                    Sie haben das Recht jederzeit Auskunft über Herkunft, den Zweck Ihrer gespeicherten personenbezogenen Daten sowie deren Empfänger zu erhalten (unentgeltlich). Sie haben außerdem das Recht, die Löschung, Sperrung oder Berichtigung dieser Daten zu verlangen. Hierzu können Sie sich jederzeit gern unter der im Impressum angegebenen Adresse an uns wenden. Dies gilt ebenfalls für weitere Fragen zum Thema Datenschutz. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                    <p/><br>
                    e. Analyse-Tools und Tools von Drittanbietern
                    <p/>
                    Der Besuch unserer Webseite kann statistisch bzgl. Ihrem Surf-Verhalten ausgewertet werden. Dies erfolgt vor allem mit Cookies sowie mit Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym. D.h. das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Es gibt keine Verbindung zwischen Ihren personengebundenen Daten und den Statistiken zum Surf-Verhalten auf unserer Seite. Sie können dieser Analyse widersprechen. Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
                    <p/><br>
                    3. Allgemeine Hinweise und Pflichtinformationen
                    <p/>
                    a. Datenschutz
                    <p/>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    Bei der Benutzung dieser Webseite werden verschiedene personenbezogene Daten erhoben. Diese Datenschutzerklärung erläutert u.a. welche Daten erhoben werden und wofür wir sie nutzen.
                    Wir weisen bereits an dieser Stelle darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                    <p/><br>
                    Hinweis zur verantwortlichen Stelle
                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser Webseite ist der Betreiber der Webseite
                    Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                    <p/><br>
                    b. Widerruf Ihrer Einwilligung zur Datenverarbeitung
                    <p/>
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Mittels einer formlosen E-Mail an uns können Sie eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                    <p/><br>
                    c. Beschwerderecht bei der zuständigen Aufsichtsbehörde
                    <p/>
                    Dem Betroffenen steht im Falle datenschutzrechtlicher Verstöße ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Die zuständige Aufsichtsbehörde für datenschutzrechtliche Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, der im Impressum aufgeführten Adresse. Übersicht der Datenschutzbeauftragten sowie der entsprechenden Kontaktdaten: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
                    <p/><br>
                    d. Recht auf Datenübertragbarkeit
                    <p/>
                    Sie haben zudem das Recht, sich die Daten die wir auf Grundlage der Erfüllung eines Vertrages bzw. Ihrer Einwilligung automatisiert verarbeiten, in einem gängigen und maschinenlesbaren Format ausgeben zu lassen.
                    <p/><br>
                    e. SSL- bzw. TLS-Verschlüsselung
                    <p/>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                    <p/><br>
                    f. Auskunft, Sperrung, Löschung
                    <p/>
                    Sie haben das Recht jederzeit Auskunft über Herkunft, den Zweck Ihrer gespeicherten personenbezogenen Daten sowie deren Empfänger zu erhalten (unentgeltlich). Sie haben außerdem das Recht, die Löschung, Sperrung oder Berichtigung dieser Daten zu verlangen. Hierzu können Sie sich jederzeit gern unter der im Impressum angegebenen Adresse an uns wenden. Dies gilt ebenfalls , für weitere Fragen zum Thema Datenschutz.
                    <p/><br>
                    g. Widerspruch gegen Werbe-Mails
                    <p/>
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                    <p/><br>
                    4. Datenerfassung auf unserer Webseite
                    <p/>
                    a. Cookies
                    <p/>
                    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
                    Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Diese enthalten die Angabe über seine Herkunft und die Speicherfrist. Weitere Daten können in einem Session-Cookie nicht gespeichert werden. Nach Ende Ihres Besuchs werden die Session-Cookies automatisch gelöscht. Zu keinem Zeitpunkt werden einzelne Nutzer identifiziert. Sie erhalten über das System keine Werbung.
                    Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Webseite eingeschränkt sein.
                    Weitere Cookies, die erforderlich sind für den elektronischen Kommunikationsvorgang oder zur von Ihnen erwünschten Funktionsbereitstellung (z.B. Warenkorb), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Webseitenbetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur optimierten und technisch fehlerfreien Bereitstellung seiner Webseite und deren Dienste. Soweit andere Cookies z.B. zur Analyse Ihres Surfverhaltens gespeichert werden, sind diese in der Datenschutzerklärung gesondert aufgeführt.
                    <p/><br>
                    b. Server-Log-Dateien
                    <p/>
                    Der Hoster dieser Webseite erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies erfolgt im Rahmen der Gewährleistung einer ordnungsgemäßen Datenverarbeitung unter Einhaltung gesetzlicher Aufbewahrungspflichten. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                    <p/><br>
                    c. Auftragsdatenverarbeitung
                    <p/>
                    Wir haben mit dem Hoster dieser Webseite einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen, in dem wir ihn verpflichten, die Daten unserer Kunden zu schützen und sie nicht an Dritte weiterzugeben.
                    <p/><br>
                    d. Kontaktformular und E-Mail
                    <p/>    
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                    Die Verarbeitung der Daten die über das Kontaktformular übermittelt wurden erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dafür ist eine formlose Mitteilung per E-Mail an uns ausreichend. Auch hier gilt: Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                    Die Daten die über das Kontaktformular an uns übermittelt wurden verbleiben bei uns, bis einer der folgenden Punkte Eintritt:
                     <li>Sie fordern uns zur Löschung auf</li>
                     <li>Sie widerrufen Ihre Einwilligung zur Speicherung</li>
                     <li>der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage)
                    Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.</li>
                    <p/><br>
                    Quelle: e-recht24.de, vom Webseitenbetreiber angepasst.
                    <p/><br>
                </v-card-text>
            </v-card>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
            widthPage: ''
        }
    },
    methods: {
        resize(){
            this.widthPage = '500px'
            if(this.$vuetify.display.xs){
                this.widthPage = '100%'
            }
        }
    },
    mounted() {
        this.resize()
    }


}
</script>
