<template>
        <div class="hsBox" :class="hsObj.highlightEntry == true ? 'hsBoxHighLight' : 'hsBoxStandard'">
            <div class="hsText subboxhs">
                <table width="100%" border=0><tr>
                <td v-if="hsObj.ranking!='1' && hsObj.ranking!='2' && hsObj.ranking!='3'" width="10%">{{ hsObj.ranking }}</td>
                <td v-if="hsObj.ranking=='1'" width="10%"><img :src="rangGold" class="imageCenter" alt="Rangliste Platz 1"></td>
                <td v-if="hsObj.ranking=='2'" width="10%"><img :src="rangSilver" class="imageCenter" alt="Rangliste Platz 2"></td>
                <td v-if="hsObj.ranking=='3'" width="10%"><img :src="rangBronze" class="imageCenter" alt="Rangliste Platz 3"></td>          
                
                <td width="38%">{{ formatStringForTable(hsObj.contenderName) }}</td>
                <td width="15%">{{ hsObj.points }}</td>
    <!--            <td width="15%">{{ hsObj.durationInSeconds }} sek</td> ## former 10-30-15-15-30-->
                <td width="37%">{{ hsObj.playerName }}</td>
                </tr></table>
            </div>
        </div>
</template> 

<script setup>
    import rangGold from '@/assets/rangliste_platz1.png'
    import rangSilver from '@/assets/rangliste_platz2.png'
    import rangBronze from '@/assets/rangliste_platz3.png'
</script>

<script>
export default {
    props: ["hsObj"],
    methods: {
        formatStringForTable(value){
            if(value.length > 15){
                value = value.substr(0,15) + " " + value.substr(15)
            }
            return value
        }
    }
}
</script>

<style>
.hsBox {
    padding: 5px;
    margin-top: 2px;
    border-radius: 8px;
}
.hsBoxStandard {
    background: #26a14d;
}
.hsBoxHighLight {
    background: #063d18;
}
.subboxhs {
    font-size: 0.8em;
    padding: 5px;
    background: #44dd66;
    border-radius: 10px;
}
</style>
