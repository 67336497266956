import { createRouter, createWebHistory } from "vue-router";
import Impressum from '../views/ImpressumSite.vue'
import Datenschutz from '../views/DatenschutzSite.vue'
import WhatsAppChannel from '../views/WhatsAppChannel.vue'
import NotFound from '../views/NotFound.vue'
import AboutUs from '../components/AboutUs.vue'

import Home from '../components/HomeSite.vue'
import MatchPage from '../components/MatchPage.vue'
import HighScore from '../components/HighScore.vue'
import GameSetup from '../components/GameSetup.vue'
import LostPage from '../components/LostPage.vue'
import SolvedPage from '../components/SolvedPage.vue'
import HowToPlay from '../components/HowToPlayMatchPage.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/aboutus',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: Impressum
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: Datenschutz
    },
    {
        path: '/whatsapp',
        name: 'WhatsAppChannel',
        component: WhatsAppChannel
    },
    {
        path: '/gamesetup',
        name: 'GameSetup',
        component: GameSetup
    },
    {
        path: '/game/:matchId',
        name: 'MatchPage',
        component: MatchPage,
        props: true
    },
    {
        path: '/howToPlay',
        name: 'HowToPlay',
        component: HowToPlay
    },
    {
        path: '/highScore/:matchId',
        name: 'HighScoreWithHightlight',
        component: HighScore,
        props: true
    },
    {
        path: '/highScore',
        name: 'HighScore',
        component: HighScore,
        props: true
    },
    {
        path: '/lost/:matchId',
        name: 'Lost',
        component: LostPage,
        props: true
    },
    {
        path: '/solved/:matchId',
        name: 'Solved',
        component: SolvedPage,
        props: true
    },
    //catchall 404 - will catch any undefined routes 
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router