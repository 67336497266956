<template>
    <Teleport to="#app">
        <Transition name="modal-fade">
            <div class="modal-wrapper" v-if="showPopUp">
                <v-card
                    class="mx-auto pa-0 mt-16"
                    :width="widthMatchpage"
                    rounded="24"
                    elevation="24"
                    >
                    <v-toolbar
                        color="success"
                        title="Zufallsjoker"
                        >
                        <img :src="logoNeg" class="logoInPopup" alt="legendenquiz logo">
                    </v-toolbar>

                    <v-card-text>
                        <div v-for="(joker, i) in data" :key="i">
                            <v-chip variant="flat" class="mb-2 mt-2 jokerChip" :class="joker.activeJoker ? 'bt-active' : 'bt-inactive'">
                                <v-icon start icon="mdi mdi-weather-lightning" class="light"></v-icon>
                                {{ joker.nameOfJoker }}
                            </v-chip>
                        </div>
                    </v-card-text>

                </v-card>
            </div>
        </Transition>
    </Teleport>
</template>

<script setup>
    import logoNeg from '@/assets/logo-negativ.png'
</script>

<script>
export default {
    props: ['data'],
    data() {
        return {
            showPopUp: false,
            widthMatchpage: ''
        }
    },
    emits: {
        finished: null
    },
    methods: {
        resize(){
            this.widthMatchpage = '400px'
            if(this.$vuetify.display.xs){
                this.widthMatchpage = '85%'
            }
        },
        closeAfterTime(){
            setTimeout(() => {
                this.showPopUp = false;
                this.$emit('finished')
            }, 7000); 
        }
    },
    mounted() {
        this.resize()
        this.showPopUp = true
        this.closeAfterTime()
    }
}

</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);

  text-align: center;
}

.modal-fade-enter-from {
  opacity: 0;
}
.modal-fade-enter-active {
  transition: 1.0s ease all;
}

.logoInPopup{
    width: 85px;
    margin-right: -10px;
}

@keyframes blink-base {
  0% { opacity: 1.0; }
  10% { opacity: 0.2; }
  20% { opacity: 1.0; }
  30% { opacity: 0.2; }
  40% { opacity: 1.0; }
  50% { opacity: 0.2; }
  60% { opacity: 1.0; }
  70% { opacity: 0.2; }
  80% { opacity: 1.0; }
  90% { opacity: 0.2; }
  100% { opacity: 0.2; }
}

@keyframes blink-active {
   1% { opacity: 0.2; }
  50% { opacity: 0.8; }
  100% { opacity: 1.0; color: white; }
}

.jokerChip{
    width: 80%;
}

.bt-inactive{
    animation-name: blink-base;
    animation-delay: 1.0s;
    animation-duration: 3.5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.bt-active {
  animation-name: blink-base, blink-active;
  animation-delay: 1.0s, 5.0s;
  animation-duration: 3.5s, 1.0s;
  animation-iteration-count: 1, 1;
  animation-timing-function: linear, linear;
  animation-fill-mode: forwards, forwards;
}

</style>