<template>
    <span class='points' :class="{plus: isPlus, minus: isMinus}">{{ displayValue }}P</span>
</template>

<script>
import gsap from "gsap";

export default {
    props: ["value", "aniDuration"],
    data() {
        return {
            isPlus: false,
            isMinus: false,
            displayValue: this.value,
            tweenValue: this.value
        };
    },
    watch: {
        value() {
            gsap.to(this, {
                duration: this.aniDuration,
                tweenValue: this.value,
                onUpdate: () => {
                    this.displayValue = Math.ceil(this.tweenValue)
                },
                onStart: () => {
                    if(this.displayValue < this.value){
                        this.isPlus = true
                    }else{
                        this.isMinus = true
                    }
                },
                onComplete: () => {
                    this.isPlus = false
                    this.isMinus = false
                }
            });
        },
    }
}

</script>

<style scoped>
    .points {
        display: inline-block;
        font-size: 1.2em;
        font-weight: bold;
    }
    .minus {
        color: rgba(233, 32, 32, 0.904);
    }
    .plus {
        color: rgba(19, 109, 19, 0.904);
    }

</style>
