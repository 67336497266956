<template>
  <div id="nav">
    <a href='/'><img :src="logoNeg" class="imageCenter" alt="legendenquiz logo home link"></a>
    <router-link :to="{ name: 'GameSetup' }">Neues Spiel</router-link>
    <router-link :to="{ name: 'HighScore' }">Rangliste</router-link>
    <v-icon @click="callFeedback" size="small" class="light">mdi mdi-comment-quote</v-icon>
  </div>

  <div v-if="showFeedback">
    <WriteFeedback @closePopUp="handleFeedback"/>
  </div>
</template>

<script setup>
    import logoNeg from '@/assets/logo-negativ.png'
</script>

<script>
import WriteFeedback from '../WriteFeedback.vue'

export default {
  name: 'NavigationBar',
  components: {
    WriteFeedback
  },
  data() {
    return {
     showFeedback: false
    }
  },
  methods: {
    callFeedback(){
      try{
        this.showFeedback=true
      } catch (e){
        console.log("exception: ", e)
      }
    },
    handleFeedback(){
      try{
        this.showFeedback=false
      } catch (e){
        console.log("exception: ", e)
      }
    }
  }
}
</script>


<style>
#nav {
  padding: 15px 15px 10px 15px;
  background: #26a14d;
  text-align: center;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
}
#nav a.router-link-exact-active {
  color: white;
  background: #0f5425;
}
.imageCenter{
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
</style>
<style scoped>
i.v-icon.v-icon {
  margin-left: 10px;
} 
</style>