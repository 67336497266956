export default class jokerController {
    
    constructor(axios){
        this.axios = axios
    }

    getJoker(uid) {
        return this.axios.get(`/legends/getJoker/${uid}`)
    }

    getJokerHint(uid) {
        return this.axios.get(`/legends/getJokerHint/${uid}`)
    }
}
