<template>
    <div v-if="showPopUp">
        <PopUp :headline="headline" :description="description" :showTextBox=true :showCancelButton=true @yes="setComplaint" @no="closePopUp"/>
    </div>
    <div v-if="showConfirmation">
        <PopUp :headline="headlineConfirmation" :description="descriptionConfirmation" :showTextBox=false :showCancelButton=false @yes="closePopUp" @no="closePopUp"/>
    </div>
</template>

<script>
import PopUp from "./PopUp.vue"

export default {
    props: ["matchId"],
    components: {
        PopUp
    },
    data() {
        return {
            showPopUp: true,
            showConfirmation: false,
            complaint: '', //kann das nicht weg??
            headline: "Fehler melden",
            description: "Du hast einen Fehler gefunden? Beschreibe bitte, worum es geht",
            headlineConfirmation: "Fehler gemeldet",
            descriptionConfirmation: "Danke! Deine Nachricht ist eingegangen. Damit hilfst du uns, besser zu werden!"
        }
    },
    emits: {
        closePopUp: null
    },
    methods: {
        closePopUp(){
            //console.log("no complaint written")
            this.$emit('closePopUp')
        },
        async setComplaint(text){
            try{
                //console.log("set complaint")
                //console.log("matchID: " + this.matchId)
                //console.log("complaint: " + text)
                await this.$api.complaintController.setComplaint(this.matchId, {complaintText:  text})
            }catch(error){
                console.log("error while writing complaint: " + error)            
            }
            this.showPopUp = false
            this.showConfirmation = true
        }
    }
}
</script>

<style>
</style>