<template>
    <div v-if="showPopUp">
        <PopUp :headline="headline" :description="description" :showTextBox=true :showCancelButton=true @yes="setFeedback" @no="closePopUp"/>
    </div>
    <div v-if="showConfirmation">
        <PopUp :headline="headlineConfirmation" :description="descriptionConfirmation" :showTextBox=false :showCancelButton=false @yes="closePopUp" @no="closePopUp"/>
    </div>
</template>

<script>
import PopUp from "./PopUp.vue"

export default {
    components: {
        PopUp
    },
    data() {
        return {
            showPopUp: true,
            showConfirmation: false,
            headline: "Feedback geben",
            description: "Du hast Feedback für uns? Was gefällt dir? Woran müssen wir noch arbeiten?",
            headlineConfirmation: "Feedback abgegeben",
            descriptionConfirmation: "Danke! Dein Feedback ist eingegangen. Damit hilfst du uns, besser zu werden!"
        }
    },
    emits: {
        closePopUp: null
    },
    methods: {
        closePopUp(){
            //console.log("no feedback written")
            this.$emit('closePopUp')
        },
        async setFeedback(text){
            try{
                //console.log("set feedback")
                //console.log("feedback: " + text)
                await this.$api.feedbackController.setFeedback({feedbackText:  text, username: 'unknown'})
            }catch(error){
                console.log("error while writing feedback: " + error)            
            }
            this.showPopUp = false
            this.showConfirmation = true
        }
    }
}
</script>

<style>
</style>