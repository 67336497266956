<template>
    <Teleport to="#app">
        <Transition name="modal-fade">
            <div class="modal-wrapper" v-if="showPopUp">
                <v-card
                    class="mx-auto pa-0"
                    :class="isMobile ? 'verticalAlignCenterMobile' : 'verticalAlignCenter'"
                    :width="widthMatchpage"
                    rounded="24"
                    elevation="24"
                    >

                    <v-toolbar
                        color="success"
                        title="Anleitung"
                        >
                        <img :src="logoNeg" class="logoInPopup" alt="legendenquiz logo">
                    </v-toolbar>

                    <v-card-text>
                        <div class="pa-4">{{ textToShow }}</div>
                    </v-card-text>

                </v-card>
            </div>
        </Transition>
    </Teleport>
</template>

<script setup>
    import logoNeg from '@/assets/logo-negativ.png'
</script>

<script>
export default {
    props: ['textToShow'],
    data() {
        return {
            showPopUp: false,
            widthMatchpage: '',
            isMobile: false
        }
    },
    methods: {
        resize(){
            this.widthMatchpage = '300px'
            if(this.$vuetify.display.xs){
                this.widthMatchpage = '80%'
                this.isMobile = true
            }
        }
    },
    mounted() {
        this.resize()
        this.showPopUp = true
    }
}

</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);

  text-align: center;
}

.verticalAlignCenter{
    margin-top: 15%;
}
.verticalAlignCenterMobile{
    margin-top: 40%;
}

.modal-fade-enter-from {
  opacity: 0;
}

.modal-fade-enter-active {
  transition: 0.8s ease all;
}

.logoInPopup{
    width: 85px;
    margin-right: -10px;
}

</style>