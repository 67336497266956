<template>
    <v-expand-transition>
        <v-container class="mt-0">
            <v-card
                class="mx-auto pa-0"
                :width="widthMatchpage"
                rounded="24"
                elevation="24"  
                height="70vh"
                >
                
                <!-- alle 5 elemente in einer Reihe, nur Icons -->
                <v-toolbar>
                    <v-row class="text-center mb-1 no-margin-for-row" justify="center" align="center">
                        <v-col class="htpJoker htp">
                            <v-fade-transition>
                                <v-icon color="success" size="large">mdi mdi-weather-lightning</v-icon>
                            </v-fade-transition><br>
                        <span class="small-text">Joker</span>
                        </v-col>
                        <v-col class="htpHint htp">
                            <v-fade-transition>
                                <v-icon color="success" size="large">mdi mdi-account-question-outline</v-icon>
                            </v-fade-transition><br>
                            <span class="small-text" >Tipp</span>
                        </v-col>
                        <v-col class="htpPoints htp">
                            <v-expand-x-transition>
                                <v-chip
                                    fab
                                    variant="tonal"
                                    size="large"
                                    >
                                    <AnimatedPoints value="3459" aniDuration="0" />
                                </v-chip>
                            </v-expand-x-transition>
                        </v-col>
                        <v-col class="htpGuess htp">
                            <v-fade-transition>
                                <v-icon size="large">mdi mdi-lightbulb-on-outline</v-icon>
                            </v-fade-transition><br>
                            <span class="small-text">Lösen</span>
                        </v-col>
                        <v-col class="htpQuit htp">
                            <v-fade-transition>
                                <v-icon size="large">mdi mdi-trash-can-outline</v-icon>
                            </v-fade-transition><br>
                            <span class="small-text">Aufgeben</span>
                        </v-col>
                    </v-row>
                </v-toolbar>

                <v-card 
                    class="overflow-auto pl-3 pr-3 htpMatchArea htp"
                    height="60vh"
                    flat
                    >           
                    <div class='singleHint'>
                        <HintBox :hintObj=this.exampleHint />
                    </div>
                    <div class='singleHint'>
                        <HintBox :hintObj=this.exampleHintTwo />
                    </div>
                </v-card>

            </v-card>
            
        </v-container>
    </v-expand-transition>

    <div v-if="showHtpPopup">
        <HowToPlayPopUp :textToShow=this.htpPopupText />
    </div>

    <v-btn variant="flat" @click="cancelHowToPlay" class="cancelButton">
        Anleitung beenden
        <v-icon start icon="mdi mdi-close-octagon-outline" class="light intended"></v-icon>
    </v-btn>
    
</template>

<script>
import HintBox from './HintBox.vue'
import AnimatedPoints from './AnimatedPoints.vue'
import HowToPlayPopUp from './HowToPlayPopUp.vue'

export default {
    components: {
        HintBox,
        AnimatedPoints,
        HowToPlayPopUp
    },
    data() {
        return {
            widthMatchpage: '',
            mobile: false,
            showHtpPopup: false,
            htpPopupText: '',
            exampleHint: {
                id: 1, 
                hintText: 'Beispieltext für einen weiteren Hinweis', 
                subHintText: '', 
                hasSubHint:false,
                costOfSubHint:0,
                costOfHint: 250,
                costOfTime: 14,
                costAtAll: 264
                },
            exampleHintTwo: {
                id: 1, 
                hintText: 'Beispieltext für einen Hinweis', 
                subHintText: '', 
                hasSubHint:true,
                costOfSubHint:250,
                costOfHint: 250,
                costOfTime: 27,
                costAtAll: 277
                }
        }
    },
    methods: {
        resize(){
            this.widthMatchpage = '600px'
            if(this.$vuetify.display.xs){
                this.mobile = true
                this.widthMatchpage = '100%'
            }
        },
        delay(milliseconds){
            return new Promise(resolve => {
                setTimeout(resolve, milliseconds);
            });
        },
        async handleHtpPopUps(){
            await this.delay(500)
            this.htpPopupText = "Wie funktioniert das Spiel? Spieler erraten - je mehr Punkte, desto besser";
            this.showHtpPopup = true;
            await this.delay(2500)
            this.showHtpPopup = false;

            await this.delay(2000)
            this.htpPopupText = "in diesem Bereich stehen alle Hinweise, die dir helfen den Spieler zu erraten";
            this.showHtpPopup = true;
            await this.delay(4000)
            this.showHtpPopup = false;

            await this.delay(2000)
            this.htpPopupText = "hier stehen deine Punkte. Hinweise, Details und auch die Zeit kosten Punkte";
            this.showHtpPopup = true;
            await this.delay(4000)
            this.showHtpPopup = false;

            await this.delay(2000)
            this.htpPopupText = "Du brauchst einen weiteren Hinweis um den Spieler zu erraten? Dann klick hier und hol dir den nächsten Tipp";
            this.showHtpPopup = true;
            await this.delay(4000)
            this.showHtpPopup = false;

            await this.delay(2000)
            this.htpPopupText = "Du kennst den Spieler? Dann klicke hier um deinen Tipp abzugeben und zu lösen";
            this.showHtpPopup = true;
            await this.delay(4000)
            this.showHtpPopup = false;

            await this.delay(2000)
            this.htpPopupText = "Joker! Teuer als Hinweise, aber sehr hilfreich. Nur einmal pro Runde möglich";
            this.showHtpPopup = true;
            await this.delay(4000)
            this.showHtpPopup = false;

            await this.delay(2000)
            this.htpPopupText = "hier kannst Du dein aktuelles Spiel aufgeben. Du kommst dann nicht in die Rangliste";
            this.showHtpPopup = true;
            await this.delay(4000)
            this.showHtpPopup = false;

            await this.delay(1500)
            this.htpPopupText = "Und nun: viel Erfolg beim Spiel!";
            this.showHtpPopup = true;
            await this.delay(2000)
            this.showHtpPopup = false;

            this.$router.push({name: 'GameSetup'})
        },
        cancelHowToPlay(){
            this.showHtpPopup = false;
            this.$router.push({name: 'GameSetup'})
        }
    },
    mounted() {
        this.resize()
        this.handleHtpPopUps()
    }
}
</script>

<style scoped>
    .small-text {
    font-size: 0.55rem;
    }
    .no-margin-for-row {
        margin: 0px;
    }

    .v-chip.v-chip--density-default {
        width: 100%;
        justify-content: center;
    }
    .singleHint {
        margin: 0px auto;
        margin-top: 10px;
        font-size: 1em;
    }


.htpMatchArea{
    animation-delay: 4.0s;
}
.htpPoints{
    animation-delay: 10.0s;
}
.htpHint{
    animation-delay: 16.0s;
}
.htpGuess{
    animation-delay: 22.0s;
}
.htpJoker{
    animation-delay: 28.0s;
}
.htpQuit{
    animation-delay: 34.0s;
}
.htp{
    animation-name: blinkActive;
    animation-duration: 1.0s;
    animation-iteration-count: 5;
}

@keyframes blinkActive {
  0% { opacity: 1.0; }
  50% { opacity: 0.5; background-color: rgba(191, 108, 26);}
  100% { opacity: 1.0; }
}

.cancelButton{
    position: fixed;
    right: 0;
    bottom: 100px;
    width: 100vw;
    z-index: 501;
}

</style>
