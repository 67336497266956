<template>
    <v-container class="mt-0">
            <v-card 
                class="mx-auto pt-2 pl-6 pr-6 overflow-auto"
                :width="widthPage"
                height="70vh"
                rounded="24"
                elevation="24"
                style="text-align: center"
                title="WhatsApp-Channel"
                >
                <v-card-text>
                    Bleib auf dem Laufenden und folge uns bei WhatsApp
                    <p/><br>
                    Hier der Direktlink: <br>
                    <a target="_blank" href='https://whatsapp.com/channel/0029VahdSyY35fLwdOs36v0J'>https://whatsapp.com/channel/0029VahdSyY35fLwdOs36v0J</a>
                    <p/><br>
                </v-card-text>
                <v-card-text>
                        <div class="small-text">per Klick auf den Link wirst du direkt zu WhatsApp weitergeleitet</div>
                    </v-card-text>

                <img :src="logo" class="imageCenter" alt="legendenquiz logo">
            </v-card>
    </v-container>
</template>

<script setup>
    import logo from '@/assets/logo.png'
</script>

<script>
export default {
    data() {
        return {
            widthPage: ''
        }
    },
    methods: {
        resize(){
            this.widthPage = '500px'
            if(this.$vuetify.display.xs){
                this.widthPage = '100%'
            }
        }
    },
    mounted() {
        this.resize()
    }


}
</script>

<style scoped>
.imageCenter{
    margin-top: 5%;
    width: 25%;
    height: auto;
}
.small-text {
    font-size: 0.55rem;
}
</style>
