
<template>
    <div v-if="showPopUp">
        <PopUp :headline="headline" :description="description" :showTextBox=false :showCancelButton=true @yes="useJoker" @no="closePopUp"/>
    </div>
    <div v-if="showJokerSelection">
        <JokerSelection :data="listWithJokerData" @finished="jokerSelectionFinished"/>
    </div>
</template>

<script>
import PopUp from "./PopUp.vue"
import JokerSelection from "./JokerSelection.vue"

export default {
    props: ["matchId", "costOfJoker"],
    components: {
        PopUp,
        JokerSelection
    },
    data() {
        return {
            showPopUp: true,
            headline: "Joker",
            description: "Willst du einen Joker für " + this.costOfJoker + " Punkte benutzen?",
            showJokerSelection: false,
            listWithJokerData: ""
        }
    },
    emits: {
        closePopUp: null,
        notEnoughPoints: null,
        useJoker: null
    },
    methods: {
        closePopUp(){
            //console.log("cancelled")
            this.$emit('closePopUp')
        },
        async useJoker() {
            //console.log("use joker")
            try{
                const joker = await this.$api.jokerController.getJoker(this.matchId)
                if(joker.status === 400){
                    console.log("http 400 - an error occurred while get joker")
                    this.$emit('closePopUp')
                } else if(joker.status === 204){
                    console.log("http 204 - an error occurred while get joker")
                    this.$emit('closePopUp')
                } else{
                    if(joker.data.status === 'LOST_BY_POINTS'){
                        console.log("lost by points - return not enough points")
                        this.$emit('notEnoughPoints')
                    }else{
                        //console.log("got joker")
                        //console.log(joker.data.jokerList)
                        this.listWithJokerData = joker.data.jokerList
                        this.showPopUp = false
                        this.showJokerSelection = true
                    }
                }
            }catch(error){
                console.log('Error while fetching joker')
            }
        },
        jokerSelectionFinished(){
            //console.log("return to matchpage")
            this.$emit('useJoker')
        }
    }
}
</script>

<style>
</style>