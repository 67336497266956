
<template>
    <div v-if="showPopUp">
        <PopUp :headline="headline" :description="description" :descriptionBelow="descriptionBelow" :moreText="moreText" :showTextBox=true :showCancelButton=true @yes="checkSolution" @no="closePopUp"/>
    </div>
    <div v-if="showConfirmation">
        <PopUp :headline="headlineConfirmation" :description="descriptionConfirmation" :showTextBox=false :showCancelButton=false @yes="closePopUpWithText" @no="closePopUpWithText"/>
    </div>
</template>

<script>
import PopUp from "./PopUp.vue"

export default {
    props: ["matchId", "costIfWrong"],
    components: {
        PopUp
    },
    data() {
        return {
            showPopUp: true,
            showConfirmation: false,
            solutionTipp: '',
            headline: "Wer bin ich?",
            description: "Name oder Künstlername",
            descriptionBelow: '',
            moreText: "-ein falscher Tipp kostet " + this.costIfWrong + " Punkte<br>-Eingabe von Nachname oder Künstlername<br>-der Vorname ist optional, wenn er eingegeben wird muss er auch richtig sein<br>-Groß-Kleinschreibung ist nicht wichtig<br>-Umlaute (z.B ö oder ß) können als oe, ss etc angegeben werden<br>-Sonderzeichen wie Accent oder Apostroph sind optional",
            headlineConfirmation: "Leider falsch",
            descriptionConfirmation: "dein Tipp war leider nicht richtig",
            matchStatus: ''
        }
    },
    emits: {
        closePopUp: null,
        wrongTipp: null
    },
    methods: {
        closePopUp(){
            //console.log("no solution tipp submitted")
            this.$emit('closePopUp')
        },
        closePopUpWithText(){
            //if points negative - show lost page, if points still positive return to matchpage
            if(this.matchStatus === 'LOST_BY_POINTS'){
                //console.log("no more points - redirect to lost page")
                this.$router.push({name: 'Lost', params: {
                    matchId: this.matchId
                }})
            }else{
                //console.log("wrong solution tipp - return to matchpage with tipp as parameter")
                this.$emit('wrongTipp', this.solutionTipp)
            }
        },
        async checkSolution(text){
            this.solutionTipp = text;
            //console.log("form to check solution submitted")
            //console.log("tipp: " + this.solutionTipp)
            const resultOfTipp = await this.$api.guessPlayerController.guessPlayerName(this.matchId, {solution:  this.solutionTipp})
            this.matchStatus = resultOfTipp.status
            //console.log(resultOfTipp)
            if(resultOfTipp.result){
                //console.log("correct solution")
                this.$router.push({name: 'Solved', params: {
                    matchId: this.matchId
                }})
            }else{
                //console.log("false solution tipp - show confirmation")
                this.showPopUp = false
                this.showConfirmation = true
            }
        }
    }
}
</script>

<style>
</style>