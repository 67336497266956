export default class hintController {
    
    constructor(axios){
        this.axios = axios
    }

    getHint(uid) {
        return this.axios.get(`/legends/getHint/${uid}`)
    }

    getSubHintForHint(hintId, uid) {
        return this.axios.get(`/legends/getSubHint/${hintId}/${uid}`).then(response => response.data)
    }
}
