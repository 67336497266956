export default class complaintController {
    
    constructor(axios){
        this.axios = axios
    }

    setComplaint(uid, data) {
        return this.axios.post(`/legends/postComplaint/${uid}`, data).then(response => response.data)
    }
}
