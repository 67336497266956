<template>
    <v-expand-transition>
        <v-container class="mt-0">
            <v-card
                v-show="showCard"
                class="mx-auto pa-0"
                :width="widthMatchpage"
                rounded="24"
                elevation="24"  
                height="70vh"
                >
                
                <!-- alle 5 elemente in einer Reihe, nur Icons -->
                <v-toolbar>
                    <v-row class="text-center mb-1 no-margin-for-row" justify="center" align="center">
                        <v-col @click="callUseJoker">
                            <v-fade-transition>
                                <v-icon v-show="showHead" :color="jokerAvailable ? 'success' : 'grey'" size="large">mdi mdi-weather-lightning</v-icon>
                            </v-fade-transition><br>
                        <span v-show="showHead" class="small-text" :class="jokerAvailable ? '' : 'disabledColor'">Joker</span>
                        </v-col>
                        <v-col @click="getHint">
                            <v-fade-transition>
                                <v-icon v-show="showHead" :color="noMoreHintsAvailable ? 'grey' : 'success'" size="large">mdi mdi-account-question-outline</v-icon>
                            </v-fade-transition><br>
                            <span v-show="showHead" class="small-text" :class="noMoreHintsAvailable ? 'disabledColor' : ''">Tipp</span>
                        </v-col>
                        <v-col>
                            <v-expand-x-transition>
                                <v-chip
                                    fab
                                    v-show="showHead"
                                    variant="tonal"
                                    size="large"
                                    >
                                    <AnimatedPoints :value="currentPoints" :aniDuration="pointAnimationDuration"/>
                                </v-chip>
                            </v-expand-x-transition>
                        </v-col>
                        <v-col @click="callGuessPlayer">
                            <v-fade-transition>
                                <v-icon v-show="showHead" size="large">mdi mdi-lightbulb-on-outline</v-icon>
                            </v-fade-transition><br>
                            <span v-show="showHead" class="small-text">Lösen</span>
                        </v-col>
                        <v-col @click="callQuitMatch">
                            <v-fade-transition>
                                <v-icon v-show="showHead" size="large">mdi mdi-trash-can-outline</v-icon>
                            </v-fade-transition><br>
                            <span v-show="showHead" class="small-text">Aufgeben</span>
                        </v-col>
                    </v-row>
                </v-toolbar>

                <v-card 
                    class="overflow-auto pl-3 pr-3"
                    height="60vh"
                    flat
                    >           
                    <Transition name="current-hint" tag="div">
                        <div class='singleHint' v-if="showCurrent">
                            <HintBox :hintObj=this.currentHint @getSubHint="getSubHint"/>
                        </div>
                    </Transition>
                    <div class='singleHint' v-for="(singleHint, i) in listOfHints" :key="i">
                        <HintBox :hintObj=singleHint @getSubHint="getSubHint"/>
                    </div>
                </v-card>

            </v-card>
        </v-container>
    </v-expand-transition>


    <div v-if="showQuitMatch">
        <QuitMatch :matchId=this.matchId @closePopUp="handleQuitGame"/>
    </div>
    <div v-if="showGuessPlayer">
        <GuessPlayer :matchId=this.matchId :costIfWrong=this.globalInfo.costOfGuessWrongPlayer @closePopUp="handleCloseGuessPlayer" @wrongTipp="handleGuessPlayerWrongTipp"/>
    </div>
    <div v-if="showNotEnoughPoints">
        <NotEnoughPoints :matchId=this.matchId @ok="handleCloseNotEnoughPoints"/>
    </div>
    <div v-if="showJoker">
        <JokerDisplay :matchId=this.matchId :costOfJoker=this.globalInfo.costOfJoker @closePopUp="handleCloseUseJoker" @useJoker="getJokerHint" @notEnoughPoints="notEnoughPointsForJoker"/>
    </div>
    <div v-if="infoPopup">
        <InfoPopUp :textToShow=this.popupTextToShow />
    </div>

    
</template>

<script>
import HintBox from './HintBox.vue'
import AnimatedPoints from './AnimatedPoints.vue'
import GuessPlayer from './GuessPlayer.vue'
import QuitMatch from './QuitMatch.vue'
import NotEnoughPoints from './NotEnoughPoints.vue'
import InfoPopUp from './InfoPopUp.vue'
import JokerDisplay from './JokerDisplay.vue'

export default {
    props: ['matchId'],
    components: {
        HintBox,
        AnimatedPoints,
        GuessPlayer,
        QuitMatch,
        NotEnoughPoints,
        InfoPopUp,
        JokerDisplay
    },
    data() {
        return {
            showCard: false,
            widthMatchpage: '',
            mobile: false,
            currentPoints: 0,
            pointAnimationDuration: 0,
            listOfHints: [],
            currentHint: {},
            showGuessPlayer: false,
            showQuitMatch: false,
            showNotEnoughPoints: false,
            showCurrent: false,
            showHead: false,
            showJoker: false,
            jokerAvailable: true,
            noMoreHintsAvailable: false,
            showInfoTextForNotEnoughPointsJoker: false,
            infoPopup: false,
            popupTextToShow: '',
            globalInfo: null
        }
    },
    methods: {
        resize(){
            this.widthMatchpage = '600px'
            if(this.$vuetify.display.xs){
                this.mobile = true
                this.widthMatchpage = '100%'
            }
        },
        async getCurrentPoints() {
            await this.delay(500)
            //console.log("matchsite called with matchId: " + this.matchId)
            try{
                const gameInfo = await this.$api.gameController.getCurrentPoints(this.matchId)
                this.pointAnimationDuration = 5.0 //count slowly at beginning
                this.changePoints(gameInfo.currentPoints)
            } catch(error) {
                console.error('Error while calling information for matchId ' + this.matchId)
            }
        },
        async getFirstHint(){
            await this.delay(1000)
            this.pointAnimationDuration = 1.5
            this.getHint()
        },
        async getHint(){
            try{
                const hint = await this.$api.hintController.getHint(this.matchId)
                //console.log("match status (hint): " + hint.data.status)
                if(hint.status === 400){
                    console.log("http 400 - an error occurred while get hint")
                }
                if(hint.status === 204){
                    console.log("http 204 - no more hints available")
                    this.popupTextToShow = "keine weiteren Hinweise vorhanden";
                    this.infoPopup = true;
                    setTimeout(() => {
                        this.infoPopup = false;
                    }, 1000); 
                }else{
                    if(hint.data.status === 'LOST_BY_POINTS'){
                        //console.log("lost by points")
                        this.showNotEnoughPoints = true //last chance - user has to solve this now or never
                    }else{
                        let hintToSet = hint.data.hint
                        this.setHintToListOfHintsWithCosts(hintToSet)
                        this.changePoints(hintToSet.pointsAfterHint)
                        //console.log("hint: ")
                        //console.log(hintToSet)
                        if(hintToSet.isLastHint === true){
                            this.noMoreHintsAvailable = true
                        }
                    }
                }
            }catch(error){
                console.log('Error while fetching new hint')
            }
        },
        async getSubHint(hintId){
            try{
                const subhint = await this.$api.hintController.getSubHintForHint(hintId, this.matchId)
                //console.log("match status (subhint): " + subhint.status)
                if(subhint.status === 400){
                    console.log("http 400 - an error occurred while get sub hint")
                }
                if(subhint.status === 'LOST_BY_POINTS'){
                    //console.log("lost by points")
                    this.showNotEnoughPoints = true //last chance - user has to solve this now or never
                }else{
                    let subhintToSet = subhint.hint
                    this.setSubHintToHint(subhintToSet)
                    this.changePoints(subhintToSet.pointsAfterHint)
                    //console.log("subhint: ")
                    //console.log(subhintToSet)
                }
            }catch(error){
                console.log('Error while fetching sub hint: ' + error)
            }
        },
        async setHintToListOfHintsWithCosts(hint){
            if(this.currentHint.id !== undefined){
                this.listOfHints.unshift(this.currentHint)
                this.currentHint = {}
                this.showCurrent = false
            }
            let hintToSave = {
                id: hint.groupNumber, 
                hintText: hint.hint, 
                subHintText: '', 
                hasSubHint:hint.hasSubHint,
                costOfSubHint:this.globalInfo.costOfSubHint,
                costOfHint: hint.costOfHint,
                costOfTime: hint.costOfTime,
                costAtAll: hint.costAtAll
                }
            this.currentHint = hintToSave
            await this.delay(100)
            this.showCurrent = true
    
        },
        setSubHintToHint(subHint){
            this.listOfHints.forEach(hint => {
                if(hint.id === subHint.groupNumber){
                    hint.subHintText = subHint.hint
                    hint.hasSubHint = false
                    hint.costOfSubHint = ''
                    hint.subHintCostOfHint = subHint.costOfHint
                    hint.subHintCostOfTime = subHint.costOfTime
                    hint.subHintCostAtAll = subHint.costAtAll
                }
            })
            if(this.currentHint.id === subHint.groupNumber){
                this.currentHint.subHintText = subHint.hint
                this.currentHint.hasSubHint = false
                this.currentHint.costOfHint = ''
                this.currentHint.subHintCostOfHint = subHint.costOfHint
                this.currentHint.subHintCostOfTime = subHint.costOfTime
                this.currentHint.subHintCostAtAll = subHint.costAtAll
            }
        },
        async getJokerHint(){
            //console.log("joker called")
            this.handleCloseUseJoker()
            try{
                const jokerHint = await this.$api.jokerController.getJokerHint(this.matchId)
                //console.log("match status (jokerHint): " + jokerHint.data.status)
                if(jokerHint.status === 400){
                    console.log("http 400 - an error occurred while get joker hint")
                }else{
                    if(jokerHint.data.status === 'LOST_BY_POINTS'){
                        //console.log("lost by points")
                        this.showNotEnoughPoints = true //last chance - user has to solve this now or never
                    }else{
                        let hintToSet = jokerHint.data.hint
                        this.setHintToListOfHintsWithCosts(hintToSet)
                        this.changePoints(hintToSet.pointsAfterHint)
                        this.jokerAvailable = false
                        //console.log("hint: ")
                        //console.log(hintToSet)
                    }
                }
            }catch(error){
                console.log('Error while fetching joker hint')
            }
        },
        callGuessPlayer(){
            this.showGuessPlayer = true
        },
        callQuitMatch(){
            this.showQuitMatch = true
        },
        callUseJoker(){
            if(!this.jokerAvailable){
                this.popupTextToShow = "es steht nur ein Joker pro Runde zur verfügung";
                if(this.showInfoTextForNotEnoughPointsJoker){
                    this.popupTextToShow = "du hast leider nicht genug Punkte für den Joker";
                }
                this.infoPopup = true;
                setTimeout(() => {
                    this.infoPopup = false;
                }, 2000); 
            }else{
                this.showJoker = true
            }
        },
        handleCloseGuessPlayer(){
            this.showGuessPlayer = false
        },
        handleCloseUseJoker(){
            this.showJoker = false
        },
        notEnoughPointsForJoker(){
            console.log("not enough points for joker")
            this.showNotEnoughPoints = true //last chance - user has to solve this now or never
        },
        handleQuitGame(){
            this.showQuitMatch = false
        },
        handleCloseNotEnoughPoints(){
            this.showNotEnoughPoints = false
            this.showGuessPlayer = true
        },
        async handleGuessPlayerWrongTipp(wrongTipp){
            //.log("wrong tipp: " + wrongTipp)
            this.handleCloseGuessPlayer()

            const getCurrentPoints = await this.$api.gameController.getCurrentPoints(this.matchId)
            let points = getCurrentPoints.currentPoints

            //generate new hint for wrong tipp and refresh points
            const costs = this.currentPoints - points
            //console.log("currentpoints: " + this.currentPoints)
            //console.log("points to subtract: " + points)
            //console.log("costs at all: " + costs)
            this.setHintToListOfHintsWithCosts({
                groupNumber: 99, 
                hint: 'falscher Tipp: ' + wrongTipp, 
                subHintText: '', 
                hasSubHint: false,
                costAtAll: costs
            })
            this.changePoints(points)
        },
        changePoints(newValue){
            this.currentPoints = newValue
            this.checkIfEnoughPointsForMore()
        },
        delay(milliseconds){
            return new Promise(resolve => {
                setTimeout(resolve, milliseconds);
            });
        },
        async startIt(){
            await this.delay(500)
            this.showHead = true
        },
        async getGlobalInfo(){
            try{
                const gInfo = await this.$api.gameController.getGlobalInformation()
                //console.log(gInfo)
                this.globalInfo = gInfo
            } catch(error) {
                console.error('Error while getting global information')
            }
        },
        checkIfEnoughPointsForMore(){
            if(this.currentPoints < this.globalInfo.costOfJoker){
                this.jokerAvailable = false
                this.showInfoTextForNotEnoughPointsJoker = true
            }
            if(this.currentPoints < this.globalInfo.costOfHint){
                this.noMoreHintsAvailable = true
            }
        }
    },
    mounted() {
        this.resize()
        this.showCard = true
        this.getGlobalInfo()
        this.startIt()
        this.getCurrentPoints()
        this.getFirstHint()
    }
}
</script>

<style scoped>
    .small-text {
    font-size: 0.55rem;
    }
    .no-margin-for-row {
        margin: 0px;
    }
    .disabledColor{
        color: rgb(117, 116, 116);
    }


    .v-chip.v-chip--density-default {
        width: 100%;
        justify-content: center;
    }
    .singleHint {
        margin: 0px auto;
        margin-top: 10px;
        font-size: 1em;
    }

    .current-hint-enter-active {
    animation: bounce-in 0.5s;
    }
    .current-hint-leave-active {
    animation: bounce-out 0.3s;
    }

    @keyframes bounce-in {
        0% { transform: scale(0);}
        100% { transform: scale(1);}
    }

    @keyframes bounce-out {
        0% { transform: scale(1);}
        50% { transform: scale(0.6);}
        100% { transform: scale(0);}
    }
</style>
