<template>
    <v-container class="mt-0">
            <v-card 
                class="mx-auto pt-2 pl-6 pr-6 overflow-auto"
                :width="widthPage"
                height="70vh"
                rounded="24"
                elevation="24"
                style="text-align: center"
                title="Impressum"
                >
                <v-card-text>
                    Anbieter und Ersteller dieser Seiten ist
                    <p/><br>
                    Michael Ziegler<br>
                    Am Stieg 6<br>
                    21635 Estebrügge<br>
                    Email: kontakt@wettstatistik24.de
                </v-card-text>

                <img :src="logo" class="imageCenter" alt="legendenquiz logo">
            </v-card>
    </v-container>
</template>

<script setup>
    import logo from '@/assets/logo.png'
</script>

<script>
export default {
    data() {
        return {
            widthPage: ''
        }
    },
    methods: {
        resize(){
            this.widthPage = '500px'
            if(this.$vuetify.display.xs){
                this.widthPage = '100%'
            }
        }
    },
    mounted() {
        this.resize()
    }


}
</script>

<style scoped>
.imageCenter{
    margin-top: 5%;
    width: 25%;
    height: auto;
}
</style>
