<template>
    <h1 v-if="showForm" class="highlightTitle" :class="titleClass">
        Neues Spiel
    </h1>
<Transition name="setupform">
    <div v-if="showForm">
        <v-container class="mt-0">
            <v-card 
                class="mx-auto pa-2 overflow-auto"
                :width="widthSetup"
                height="70vh"
                rounded="24"
                elevation="24"
                >

                <v-container class="lessPaddingContainer">
                    <v-text-field
                        class="cardText green"
                        label="dein Name *"
                        variant="solo-filled"
                        prepend-icon="mdi-account"
                        autofocus
                        v-model="contenderName"
                        :rules="validationSatisfiedForContendername"
                        />

                    <v-checkbox
                        id="terms"
                        class="cardText"
                        label="Nutzungsbedingungen akzeptieren"
                        color="success"
                        hide-details="auto"
                        v-model="terms"
                        :error="termsError"
                        :rules="validationSatisfiedForTerms"
                        />

                    <v-row>
                        <v-col cols="6">
                            <v-sheet>
                                <span class="categoryIdentifier">
                                    Spielart
                                </span>
                                <v-icon size="xsmall" class="mdi mdi-help-circle-outline" @click="callHelpForMatchType" />
                            </v-sheet>
                        </v-col>
                        <v-col>
                            <v-sheet>
                                <Transition name="optionsBox">
                                    <v-chip variant="flat" class="boxDiffLevel" v-if="matchType === 'SINGLE'" @click="changeMatchType()">
                                        <v-icon start icon="mdi mdi-hand-pointing-right" class="light"></v-icon>
                                        Einfaches Spiel
                                    </v-chip>
                                    <v-chip variant="flat" class="boxDiffLevel" v-else-if="matchType === 'RANKING'" @click="changeMatchType()">
                                        <v-icon start icon="mdi mdi-trophy" class="light"></v-icon>
                                        Ranglisten Spiel
                                    </v-chip>
                                </Transition>
                            </v-sheet>
                        </v-col>
                        <v-col />
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-sheet>
                                <span class="categoryIdentifier">
                                    Schwierigkeitsgrad
                                </span>
                            </v-sheet>
                        </v-col>
                        <v-col cols="4">
                            <v-sheet>
                                <Transition name="optionsBox">
                                    <v-chip variant="flat" class="boxDiffLevel" v-if="difficultyLevel === 'EASY'" @click="updateDifficultyLevel(this.difficultyLevel)">
                                        <v-icon start :icon="difficultyLevelIcon" class="light"></v-icon>
                                        Einfach
                                    </v-chip>
                                    <v-chip variant="flat" class="boxDiffLevel" v-else-if="difficultyLevel === 'MID'" @click="updateDifficultyLevel(this.difficultyLevel)">
                                        <v-icon start :icon="difficultyLevelIcon" class="light"></v-icon>
                                        Mittel
                                    </v-chip>
                                    <v-chip variant="flat" class="boxDiffLevel" v-else-if="difficultyLevel === 'HEAVY'" @click="updateDifficultyLevel(this.difficultyLevel)">
                                        <v-icon start :icon="difficultyLevelIcon" class="light"></v-icon>
                                        Schwer
                                    </v-chip>
                                </Transition>
                            </v-sheet>
                        </v-col>
                        <v-col>
                            <v-sheet>
                                <div :class="showMoreGameOptions ? 'hide' : ''">
                                    <v-icon size="xsmall" class="mdi mdi-chevron-double-down" @click="callShowMoreGameOptions" />
                                </div>
                                <div :class="showMoreGameOptions ? '' : 'hide'">
                                    <v-icon size="xsmall" class="mdi mdi-chevron-double-up" @click="callHideMoreGameOptions" />
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-row v-if="showMoreGameOptions">
                        <v-col cols="6">
                            <v-sheet>
                                <span class="categoryIdentifier">
                                    Karriereende
                                </span>
                            </v-sheet>
                        </v-col>
                        <v-col>
                            <v-sheet>
                                <div v-for="(filter, i) in filterOptionsCareerEnd" :key="i">
                                    <Transition name="optionsBox">
                                        <v-chip variant="flat" class="boxDiffLevel" v-if="filterOptionsCareerEndDisplay == filter.displayText" @click="changeFilterCareerEnd(filter.enumText)">
                                            {{ filter.displayText }}
                                        </v-chip>
                                    </Transition>
                                </div>
                            </v-sheet>
                        </v-col>
                        <v-col />
                    </v-row>
                    <v-row>
                        <v-spacer class="mt-2" />
                    </v-row>
                </v-container>

                <v-divider></v-divider>
                
                <v-container class="lessPaddingContainer">    
                    <v-row>
                        <v-col cols="6">
                            <v-sheet>
                                <span class="categoryIdentifier">
                                    Du hast gewählt:
                                </span>
                            </v-sheet>
                        </v-col>
                    </v-row>
            <!--
                    <v-row class="lessTopMargin">
                        <v-col cols="6">
                            <v-sheet>
                                <span class="categoryIdentifier">
                                    <v-icon start icon="mdi mdi-chevron-double-right"></v-icon>
                                    Anzahl Runden
                                </span>
                            </v-sheet>
                        </v-col>
                        <v-col>
                            <v-sheet>
                                <span class="categoryValue">
                                    {{ numberOfMatches }}
                                </span>
                            </v-sheet>
                        </v-col>
                    </v-row>
            -->
                    <v-row class="lessTopMargin">
                        <v-col cols="6">
                            <v-sheet>
                                <span class="categoryIdentifier">
                                    <v-icon start icon="mdi mdi-chevron-double-right"></v-icon>
                                    Legendenlevel
                                </span>
                            </v-sheet>
                        </v-col>
                        <v-col>
                            <span class="categoryValue">
                                {{ difficultyLevelDesription }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="lessTopMargin">
                        <v-col cols="6">
                            <v-sheet>
                                <span class="categoryIdentifier">
                                    <v-icon start icon="mdi mdi-chevron-double-right"></v-icon>
                                    Startpunktzahl
                                </span>
                            </v-sheet>
                        </v-col>
                        <v-col>
                            <v-sheet>
                                <v-chip class="inactiveChip">
                                    <AnimatedPoints :value="this.startingPoints" :aniDuration="2" />
                                </v-chip>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-spacer class="mt-1" />
                    </v-row>
                </v-container>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                            <v-btn variant="flat" @click="callHowToPlay" class="lessTopMargin">
                                Anleitung
                            <v-icon start icon="mdi mdi-rocket-launch-outline" class="light intended"></v-icon>
                            </v-btn>
                            <v-btn variant="flat" @click="handleSubmit" class="lessTopMargin">
                                Los gehts
                            <v-icon start icon="mdi mdi-rocket-launch-outline" class="light intended"></v-icon>
                            </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </div>
</Transition>

<div v-if='startCountdown' class="setTextToCenter">
    <AnimatedCountdown @countdownEnd="startGame" />
</div>
<div v-if="showHelpMatchtype">
    <PopUp :headline="mtHeadline" :description="mtDescription" descriptionBelow="" :showTextBox=false :showCancelButton=false @yes="closePopUp" @no="closePopUp"/>
</div>

</template>

<script>
import AnimatedPoints from "./AnimatedPoints.vue"
import AnimatedCountdown from './AnimatedCountdown.vue'
import PopUp from "./PopUp.vue"

export default {
    data() {
        return {
            widthSetup: '',
            startCountdown: false,
            showForm: false,
            contenderName: '',
            terms: false,
            difficultyLevel: '',
            difficultyLevelDesription: '',
            difficultyLevelIcon: '',
            startingPoints: 0,
            filterOptionsCareerEnd: '',
            filterOptionsCareerEndValue: '',
            filterOptionsCareerEndDisplay: '',
            matchId: '',
            contenderNameError: false,
            termsError: '',
            matchType: 'RANKING',
            numberOfMatches: 3,
            titleClass: '',
            globalInfo: {},
            showHelpMatchtype: false,
            showMoreGameOptions: false,
            mtHeadline: "Erklärung",
            mtDescription: "<u>Einfaches Spiel</u><br/>du spielst eine Runde mit einer Legende. Der perfekte Einstieg um das Spiel kennen zu lernen<br/><br/><u>Ranglisten Spiel</u><br/>drei Runden, drei verschiedene Legenden. Du willst in der Rangliste nach oben? Dann ist das der richtige Modus für dich",
        }
    },
    components: {
        AnimatedPoints,
        AnimatedCountdown,
        PopUp
    },
    computed: {
        validationSatisfiedForContendername() {
            return [
                (v) => {
                    //console.log("Vcn: " + v)
                    var tooShort = v.length < 3 ? true : false
                    var tooLong = v.length > 20 ? true : false
                
                    if(tooShort || tooLong) {
                        this.contenderNameError = true
                        return 'bitte zwischen 3 und 20 Zeichen'
                    }
                    if (v.includes('  ')){
                        this.contenderNameError = true
                        return 'max. 1 Leerzeichen'
                    }
                    if (!/^[a-zA-Z0-9-_ üÜäÄöÖ]*$/.test(v)){
                        this.contenderNameError = true
                        return 'Sonderzeichen sind nicht erlaubt'
                    }
                    this.contenderNameError = false
                    return true;                
                }
            ];
        },
        validationSatisfiedForTerms() {
            return [
                (v) => {
                    //console.log("Vt: " + v)
                    if(!v){
                        if(!v === ''){
                            this.termsError = true
                            return 'bitte akzeptiere unsere Bedingungen'
                        }
                    }
                    this.termsError = false
                    return true
                }
            ];
        }
    },
    methods: {
        resize(){
            this.widthSetup = '400px'
            this.titleClass = 'highlightTitleSetup'
            if(this.$vuetify.display.xs){
                this.widthSetup = '100%'
                this.titleClass = 'highlightTitleXs'
            }
        },
        updateDifficultyLevel(oldValue){
            if(oldValue === 'HEAVY') { 
                this.difficultyLevel = 'EASY'
                this.startingPoints = this.globalInfo.startingPointsLevelEasy
                this.difficultyLevelDesription = 'Legenden die jeder kennt'

                this.difficultyLevelIcon = 'mdi mdi-baby'
            }
            if(oldValue === 'EASY') { 
                this.difficultyLevel = 'MID'
                this.startingPoints = this.globalInfo.startingPointsLevelMid
                this.difficultyLevelDesription = 'Halbwissen reicht hier nicht mehr'
                this.difficultyLevelIcon = 'mdi mdi-run'
            }
            if(oldValue === 'MID') { 
                this.difficultyLevel = 'HEAVY'
                this.startingPoints = this.globalInfo.startingPointsLevelHeavy
                this.difficultyLevelDesription = 'empfohlen für echte Experten!'
                this.difficultyLevelIcon = 'mdi mdi-weight-lifter'
            }
        },
        changeMatchType(){
            if(this.matchType === 'SINGLE'){
                this.matchType = 'RANKING'
                this.numberOfMatches = 3
            }else{
                this.matchType = 'SINGLE'
                this.numberOfMatches = 1
            }
        },
        initFilterCareerEnd(){
            this.filterOptionsCareerEnd = this.globalInfo.filter[0].filterValues;
            this.filterOptionsCareerEndValue = this.globalInfo.filter[0].filterValues[0].enumText;
            this.filterOptionsCareerEndDisplay = this.globalInfo.filter[0].filterValues[0].displayText;
        },
        changeFilterCareerEnd(oldValue){
            for(let i=0; i<this.filterOptionsCareerEnd.length; i++){
                if(this.filterOptionsCareerEnd[i].enumText == oldValue){
                    if(this.filterOptionsCareerEnd[i].enumText == this.filterOptionsCareerEnd[this.filterOptionsCareerEnd.length-1].enumText){
                        this.filterOptionsCareerEndValue = this.filterOptionsCareerEnd[0].enumText;
                        this.filterOptionsCareerEndDisplay = this.filterOptionsCareerEnd[0].displayText;
                    }else{
                        this.filterOptionsCareerEndValue = this.filterOptionsCareerEnd[i+1].enumText;
                        this.filterOptionsCareerEndDisplay = this.filterOptionsCareerEnd[i+1].displayText;
                    }
                }
            }
        },
        handleSubmit() {
            if(!this.terms) this.termsError = true
            if(this.terms) this.termsError = false

            if(!this.termsError && !this.contenderNameError){
                //console.log('start game for contenderName: ', this.contenderName)
                //console.log('start with difficulty ', this.difficultyLevel)
                this.startTheCountdown()
            }
        },
        callHowToPlay(){
            this.$router.push({name: 'HowToPlay'})
        },
        startTheCountdown(){
            //console.log("start the countdown")
            this.showForm = false
            this.startCountdown = true
        },
        async getStartingPointsAndSetLevelInitially(){
            this.updateDifficultyLevel('HEAVY') //must be set to initialize the chip
            try{
                const gInfo = await this.$api.gameController.getGlobalInformation()
                //console.log(gInfo)
                this.globalInfo = gInfo
                this.updateDifficultyLevel('HEAVY') //set level easy initially - so call method with value HEAVY
                this.initFilterCareerEnd()
            } catch(error) {
                console.error('Error while getting global information')
            }
        },
        async startGame() {
            try{
                const game = await this.$api.gameController.start({contenderName:  this.contenderName, difficulty: this.difficultyLevel, matchType: this.matchType, filterOptionCareerEnd: this.filterOptionsCareerEndValue})
                this.matchId = game.hashcode
                //console.log(game)
                this.$router.push({name: 'MatchPage', params: {
                    matchId: this.matchId
                }})
            } catch(error) {
                console.error('Error while starting new game')
            }
        },
        callHelpForMatchType(){
            this.showHelpMatchtype = true
        },
        closePopUp(){
            this.showHelpMatchtype = false
        },
        callShowMoreGameOptions(){
            this.showMoreGameOptions = true
        },
        callHideMoreGameOptions(){
            this.showMoreGameOptions = false
        },
    },
    mounted() {
        this.resize()
        this.showForm = true
        this.getStartingPointsAndSetLevelInitially()
    }
}
</script>

<style>
    .cardText .v-label {
        font-size: 0.85em;
        text-transform: none;
    }
    .categoryIdentifier {
        font-size: 0.8em;
        color: #6e6d6d;
        text-transform: none;
    }
    .categoryValue {
        font-size: 1.0em;
        color: #6e6d6d;
        text-transform: none;
    }
    .boxDiffLevel {
        position: absolute !important;
    }
    .setTextToCenter {
        text-align: center;
    }
    .setupform-enter-active {
        transition: all 1.0s ease;
    }
    .setupform-enter-from {
        opacity: 0;
        transform: translateY(100px);
    }
    .optionsBox-enter-active,
    .optionsBox-leave-active {
        transition: all 0.5s ease-out;
    }
    .optionsBox-enter-from {
        opacity: 0;
        transform: translateY(-30px);
    }
    .optionsBox-leave-to {
        opacity: 0;
        transform: translateY(200px);
    }
    .inactiveChip{
        color: #6e6d6d !important;
        background-color: #c8e6c900 !important;
    }
    .lessTopMargin{
        margin-top: 5px !important;
    }
    .lessPaddingContainer{
        padding-top: 5px !important;
        padding-bottom: 10px !important;
    }
</style>