<template>
  <div id="nav" class="footer">
    <router-link :to="{ name: 'Datenschutz' }" >Datenschutz</router-link>
    <router-link :to="{ name: 'AboutUs' }" ><v-icon size="small" class="light">mdi mdi-chart-timeline-variant-shimmer</v-icon></router-link>
    <router-link :to="{ name: 'WhatsAppChannel' }" ><v-icon size="small" class="light">mdi mdi-whatsapp</v-icon></router-link>
    <!--<a target="_blank" href='https://whatsapp.com/channel/0029VahdSyY35fLwdOs36v0J'><v-icon size="small" class="light">mdi mdi-whatsapp</v-icon></a>-->
    <router-link :to="{ name: 'Impressum' }" >Impressum</router-link>
  </div>

</template>

<script>
export default {
  name: 'FooterBar',
  data() {
    return {
    }
  }
}
</script>

<style scoped>
.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: transparent !important;
}
</style>