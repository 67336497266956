export default class feedbackController {
    
    constructor(axios){
        this.axios = axios
    }

    setFeedback(data) {
        return this.axios.post(`/legends/postFeedback`, data).then(response => response.data)
    }
}
