<template>
    <v-container class="mt-0">
            <v-card 
                class="mx-auto pt-2 pl-6 pr-6 overflow-auto"
                :width="widthPage"
                height="70vh"
                rounded="24"
                elevation="24"
                style="text-align: center"
                title="Über uns - die Geschichte"
                >
                <div>
                <v-timeline align="start" side="end">
                    <v-timeline-item dot-color="green" size="small">
                        <div>
                            <div class="headline">08.09.2024</div>
                            <p>
                                200! Neuer Meilenstein für die Anzahl an Legenden beim Legendenquiz. Neu sind u.a. Yaya Touré, Butragueno und Steve McManaman
                            </p>
                        </div>
                    </v-timeline-item>
                    <v-timeline-item dot-color="green" size="small">
                        <div>
                            <div class="headline">18.07.2024</div>
                            <p>
                                wir haben nun bereits über 180 Legenden online. Die größten Stars der Bundesliga, der Premier League und den anderen Top-Ligen Europas und der Welt. Neu sind u.a. Fernando Torres, Diego Milito oder auch Thiago
                            </p>
                        </div>
                    </v-timeline-item>
                    <v-timeline-item dot-color="green" size="small">
                        <div>
                            <div class="headline">13.06.2024</div>
                            <p>
                                Unser neuer WhatsApp-Kanal ist online - hier ist der <a target="_blank" href="https://whatsapp.com/channel/0029VahdSyY35fLwdOs36v0J">Link</a>
                            </p>
                        </div>
                    </v-timeline-item>
                     <v-timeline-item dot-color="green" size="small">
                        <div>
                            <div class="headline">11.06.2024</div>
                            <p>
                                wir pflegen weiter Legenden für euch! Fast 150 der besten und beliebtesten Fussballer aller Zeiten sind aktuell schon verfügbar. Neu sind u.a. Christian Vieri, Patrick Vieira und Davor Suker
                            </p>
                        </div>
                    </v-timeline-item>
                    <v-timeline-item dot-color="green" size="small">
                        <div>
                            <div class="headline">10.06.2024</div>
                            <p>
                                jetzt neu: du weißt nicht weiter? Hol dir Hilfe mit einem von drei Jokern!
                            </p>
                        </div>
                    </v-timeline-item>

                    <v-timeline-item dot-color="green" size="small">
                        <div>
                            <div class="headline">16.05.2024</div>
                            <p>
                                der neue Ranglisten Modus ist online!<br>1 Spiel - 3 Runden - 3 Legenden! Probier es gleich aus. Mittlerweile sind über 100 der besten Fussballer aller Zeiten verfügbar - neu dabei u.a. Beckham, Völler und Zidane
                            </p>
                        </div>
                    </v-timeline-item>

                    <v-timeline-item dot-color="green" size="small">
                        <div>
                            <div class="headline">09.03.2024</div>
                            <p>
                                75! Unsere Datenbank wächst immer weiter  - wir haben schon über 75 Fussball Legenden aus allen Zeitaltern! Maradona, Pele, Müller, Matthäus - die Größten, die Besten, die Erfolgreichsten - alle sind dabei. Und es werden immer mehr...
                            </p>
                        </div>
                    </v-timeline-item>

                    <v-timeline-item dot-color="green" size="small">
                        <div>
                            <div class="headline">29.02.2024</div>
                            <p>
                                endlich online! Ein Meilenstein! Nach vielen Tagen Entwicklung und Datenpflege ist es endlich soweit. 
                            </p>
                        </div>
                    </v-timeline-item>

                    <v-timeline-item dot-color="green" size="small">
                        <div>
                            <div class="headline">Nov. 2023</div>
                            <p>
                                die Idee ist geboren: 
                                Warum nicht eine Webseite oder eine App zum Quizzen der größten Fussballlegenden erstellen? 
                                Wenn uns als Vater und Sohn das Raten Spaß macht, macht es sicher auch vielen anderen Spaß
                            </p>
                        </div>
                    </v-timeline-item>
                </v-timeline>
                </div>
                <img :src="logo" class="imageCenter" alt="legendenquiz logo">
            </v-card>
    </v-container>
</template>

<script setup>
    import logo from '@/assets/logo.png'
</script>

<script>
export default {
    data() {
        return {
            widthPage: ''
        }
    },
    methods: {
        resize(){
            this.widthPage = '500px'
            if(this.$vuetify.display.xs){
                this.widthPage = '100%'
            }
        }
    },
    mounted() {
        this.resize()
    }


}
</script>

<style scoped>
.imageCenter{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 25%;
    height: auto;
}
.headline{
    border-bottom: 1px solid #bcc;
    margin-bottom: 10px;
    font-size: 1.3em;
}
</style>
