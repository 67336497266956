<template>
  <NavigationBar />
  <div class="container1">
    <router-view />
  </div>
  <FooterBar />

</template>

<script>
import FooterBar from './components/frame/FooterBar.vue'
import NavigationBar from "./components/frame/NavigationBar.vue"

export default {
  name: 'App',
  components: {
    NavigationBar,
    FooterBar
  },
  data() {
    return {
    }
  }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .container1 {
    flex: 1;
    margin-top: -10px;
  }
  .container {
    flex: 1;
    margin-top: 15px;
    margin: 0px auto;
    font-size: 1em;
    background: #e9fdec;
    border-radius: 20px;
    width: 65%;
  }
  body {
    margin: 0;
    background: #26a14d;
  }
  h1 {
    border-bottom: 1px solid #ddd;
    display: inline-block;
    padding-bottom: 5px;
    font-size: 1em;
  }
  button {
      border: 0;
      padding: 10px 20px;
      margin-top: 20px;
      color: white;
      border-radius: 20px;
  }
  .submit {
      text-align: center;
  }
  .error {
    color: #ff0062;
    margin-top: 10px;
    font-size: 0.8em;
    font-weight: bold;
  }
  .heavy{
    font-weight: bold;
  }

  .v-icon.v-icon{
        color: #26a14d;
  }
  .light.v-icon.v-icon, .v-btn.v-icon {
        color: #e9fdec;
  }
  .v-btn.v-icon {
        color: #bd0e8e;
  }
  .intended.v-icon.v-icon {
    margin-left: 10px;
  } 
  .highlightTitle{
      transform: rotate(-5deg);
      text-align: center;
      position: absolute;
      z-index: 10;
      background-color: rgb(191, 108, 26);
      color: white;
      border-radius: 8px;
  }
  .highlightTitleXs{
      width: 40%;
      margin-left: 2%;
  }
  .highlightTitleHs{
      width: 200px;
      margin-left: 31%;
  }
  .highlightTitleSetup{
      width: 200px;
      margin-left: 37%;
  }
  .hide{
    display: none;
  }
  @media only screen and (max-width: 800px) {
    .container {
        width: 100%;
    }
    #app {
      min-height: 86vh;
    }
  }
</style>
