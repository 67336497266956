<template>
    <div class="hintBox">
        <div class="subboxhint">
            <div v-html="hintObj.hintText" />
            <span v-if="hintObj.hasSubHint">
                <div class="hb">
                <button class="hintButton" @click.self="getSubHint(hintObj.id)">mehr Details? ({{hintObj.costOfSubHint}}P)</button>
                </div>
            </span>
        </div>
    <div v-if="hintObj.costOfHint" class="costInfo">Hinweis: {{ hintObj.costOfHint }}P, Zeit: {{ hintObj.costOfTime }}P - Gesamtabzug {{ hintObj.costAtAll }}P</div> 
    <div v-if="!hintObj.costOfHint && hintObj.costAtAll" class="costInfo">Gesamtabzug {{ hintObj.costAtAll }}P</div>
    </div>
    <div class="hintBox" v-if="!!hintObj.subHintText">
        <div class="subboxsubhint">
            <span>
              {{ hintObj.subHintText }}
            </span>
        </div>
       <div class="costInfo">Hinweis: - {{ hintObj.subHintCostOfHint }}P, Zeit: - {{ hintObj.subHintCostOfTime }}P - Gesamtabzug {{ hintObj.subHintCostAtAll }}P</div>
    </div>
</template>


<script>
export default {
    props: ["hintObj"],
    emits: {
        getSubHint: null
    },
    methods: {
        getSubHint(groupNumber){
            //console.log('getsubhint for hint ' + groupNumber)
            this.$emit('getSubHint', groupNumber)
        }
    }
}
</script>

<style>
.hb {
    text-align: right;
}
.hintBox {
    padding: 5px;
    margin-top: 2px;
    background: #26a14d;
    border-radius: 8px;
}
.subboxhint {
    padding: 5px;
    background: #44dd66;
    border-radius: 10px;
}
.subboxsubhint {
    padding: 5px;
    margin-left: 15%;
    margin-right: 0%;
    background: #44dd66;
    border-radius: 10px;
}
.hintButton {
    background: #1a9c43;
    border: 0;
    padding: 2px 20px;
    color: white;
    border-radius: 20px;
    margin-top: 0px;
    font-size: 0.7em;
}
.costInfo{
    font-size: 0.7em;
    text-align: right;
    color: white;
}
</style>
