<template>
<Transition name="showSite">
    <div v-if="showSite">
        <v-container class="mt-0">
            <v-card 
                class="mx-auto pt-2 pl-6 pr-6 overflow-auto"
                :width="widthSetup"
                height="65vh"
                rounded="24"
                elevation="24"
                style="text-align: center"
                >
                <Transition name="displayFullPlayerName">
                    <div v-if="showButtons">
                        <v-card 
                            class="mb-1"
                            flat
                            position="absolute"
                            >
                            <v-icon @click="callComplaint" color="warning" size="small">mdi mdi-alert-outline</v-icon>
                        </v-card>
                    </div>
                </Transition>

                <Transition name="displayFullPlayerName">
                    <v-card-title v-if="showIntro">
                        dein Tipp ist richtig !!
                    </v-card-title>
                </Transition>
       
                <v-divider />

                <Transition name="displayFullPlayerName">
                    <div v-if="showFullPlayerName">
                    <v-card-text class="text-h4">
                        {{ this.fullName }}
                    </v-card-text>
                    <v-card-text class="text-subtitle-1" >
                        erkannt in {{ duration }} Sekunden
                    </v-card-text>
                    </div>
                </Transition>

                <Transition name="displayFullPlayerName">
                    <div v-if="showPoints">
                        <v-card-text class="text-subtitle-1" >
                            deine Punkte
                        </v-card-text>
                        <v-card-text class="text-h3">
                            <AnimatedPoints :value="pointsToShow" :aniDuration="this.animationDuration" />
                        </v-card-text>
                    </div>
                </Transition>

                <Transition name="displayFullPlayerName">
                    <div v-if="showButtons">
                        <div v-if="!isLastRound">
                            <v-btn class="ma-2" @click="linkNextRound">
                                {{ round==2 ? 'Letzte Runde' : 'Nächste Runde'}}
                                <v-icon start icon="mdi mdi-soccer" class="light intended"></v-icon>
                            </v-btn>
                        </div>
                        <div v-if="isLastRound">
                            <v-btn class="ma-2" @click="linkHighscore">
                                Rangliste
                                <v-icon start icon="mdi mdi-billboard" class="light intended"></v-icon>
                            </v-btn>
                            <v-btn class="ma-2" @click="startGame">
                                Neues Spiel
                                <v-icon start icon="mdi mdi-soccer" class="light intended"></v-icon>
                            </v-btn>
                        </div>
                    </div>
                </Transition>
            </v-card>
        </v-container>
    </div>
</Transition>

<div v-if="showComplaint">
    <WriteComplaint :matchId=this.matchId @closePopUp="handleComplaint"/>
</div>

</template>


<script>
import AnimatedPoints from './AnimatedPoints.vue'
import WriteComplaint from './WriteComplaint.vue'

export default {
    props: ['matchId'],
    components: {
        AnimatedPoints,
        WriteComplaint
    },
    data() {
        return {
            widthSetup: '',
            showSite: false,
            fullName: '',
            finalPoints: 0,
            duration: '',
            pointsToShow: 0,
            animationDuration: 5,
            showIntro: false,
            showFullPlayerName: false,
            showPoints: false,
            showButtons: false,
            showComplaint: false,
            isLastRound: true,
            round: 0
        }
    },
    methods: {
        resize(){
            this.widthSetup = '400px'
            if(this.$vuetify.display.xs){
                this.widthSetup = '100%'
            }
        },
        async getGameInfo(){
            try{
                const info = await this.$api.gameController.getGameInformation(this.matchId)
                this.fullName = info.playerFullName
                this.finalPoints = info.finalPoints
                this.duration = info.duration
                this.isLastRound = info.isLastRound
                this.round = info.round

                if(info.gameStatus != "SOLVED"){
                    console.log("error - solved page on not solved game")
                }
                //console.log(info)
            }catch(error){
                console.log('Error while fetching game info: ' + error)
            }
        },
        async showDetails(){
            await this.delay(500)
            this.showIntro = true
            await this.delay(1500)
            this.showFullPlayerName = true
            await this.delay(500)
            this.showPoints = true
            await this.delay(500)
            this.pointsToShow = this.finalPoints
            await this.delay(this.animationDuration * 1000)
            this.showButtons = true
        },
        delay(milliseconds){
            return new Promise(resolve => {
                setTimeout(resolve, milliseconds);
            });
        },
        startGame(){
            this.$router.push({name: 'GameSetup'})
        },
        linkHighscore(){
            this.$router.push({name: 'HighScoreWithHightlight', params: {
                    matchId: this.matchId
                }})
        },
        callComplaint(){
            this.showComplaint = true
        },
        handleComplaint(){
            this.showComplaint = false
        },
        async linkNextRound() {
            try{
                const game = await this.$api.gameController.startNextRound(this.matchId)
                //console.log(game)
                this.$router.push({name: 'MatchPage', params: {
                    matchId: game.hashcode
                }})
            } catch(error) {
                console.error('Error while starting new game')
            }
        },
    },
    mounted() {
        this.resize()
        this.showSite = true
        this.getGameInfo()
        this.showDetails()
    }
}
</script>

<style scoped>
.text-h4 {
    line-height: 2.5rem !important;
}

.displayFullPlayerName-enter-from {
  opacity: 0;
}
.displayFullPlayerName-enter-active {
  transition: 2.0s ease all;
}
.showSite-enter-active {
        transition: all 1.0s ease;
}
.showSite-enter-from {
    opacity: 0;
    transform: translateY(100px);
}

</style>