import '@mdi/font/css/materialdesignicons.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {createAPI} from '@/api'

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const api = createAPI()
const app = createApp(App)
const vuetify = createVuetify({
    components,
    directives,
    defaults: {
        VBtn: {
          color: '#26a14d',
          rounded: false,
          elevation: "24"
        },
        VChip: {
            elevation: "5",
            color: '#26a14d',
            variant: "flat"
        },
        VCard: {
            color: "#dee8e1"
        },
        VSheet: {
            color: "#dee8e1"
        }
      }
});
  
app.use(api)
app.use(router)
app.use(vuetify)

app.mount('#app')

