<template>
    <PopUp :headline="headline" :description="description" :showTextBox=false :showCancelButton=false @yes="redirectToMatchpage"/>
</template>

<script>
import PopUp from "./PopUp.vue"

export default {
    props: ["matchId"],
    components: {
        PopUp
    },
    emits: {
        ok: null
    },
    data() {
        return {
            headline: "Nicht genug Punkte",
            description: "du hast nicht genug Punkte für diesen Hinweis"
        }
    },
    methods: {
        redirectToMatchpage(){
            //leave page and route to lost page
            //console.log("redirectToMatchpage")
            this.$emit('ok')
        }
    }
}
</script>

<style>
</style>