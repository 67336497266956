<template>
    <PopUp :headline="headline" :description="description" :showTextBox=false :showCancelButton=true @yes="quitGame" @no="closeQuitGamePopUp"/>
</template>

<script>
import PopUp from "./PopUp.vue"

export default {
    props: ["matchId"],
    components: {
        PopUp
    },
    data() {
        return {
            headline: "Spiel beenden",
            description: "willst du das aktuelle Spiel wirklich abbrechen?"
        }
    },
    emits: {
        closePopUp: null
    },
    methods: {
        async quitGame(){
            //leave page and route to cancellation site
            //console.log("quit game")
            try{
                await this.$api.gameController.quitGame(this.matchId)
            }catch(error){
                console.log('Error while quitting game: ' + error)
            }
            this.$router.push({name: 'Lost', params: {
                matchId: this.matchId
            }})
        },
        closeQuitGamePopUp(){
            //console.log("do not quit game - abborted")
            this.$emit('closePopUp')
        },
    }
}
</script>

<style>
</style>