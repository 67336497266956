<template>
<Transition name="showSite">
    <div v-if="showSite">
        <v-container class="mt-0">
            <v-card 
                class="mx-auto pt-2 pl-6 pr-6 overflow-auto"
                :width="widthSetup"
                height="60vh"
                rounded="24"
                elevation="24"
                style="text-align: center"
                >
                <v-card 
                    class="mb-1"
                    flat
                    position="absolute"
                    >
                    <v-icon @click="callComplaint" color="warning" size="small">mdi mdi-alert-outline</v-icon>
                </v-card>

                <v-card-title>
                    Game Over {{ this.result }}
                </v-card-title>
                
                <v-divider />

                <Transition name="displayFullPlayerName">
                    <v-card-text class="text-subtitle-1" v-if="showIntro">
                        Leider hast du die Legende nicht erkannt. Es war:
                    </v-card-text>
                </Transition>
                <Transition name="displayFullPlayerName">
                    <v-card-text class="text-h4" v-if="showFullPlayerName">
                        {{ this.fullName }}
                    </v-card-text>
                </Transition>
                <br />
                <Transition name="displayFullPlayerName">
                    <div v-if="showNewGameButton">
                        <v-btn @click="startGame">
                            Neues Spiel
                            <v-icon start icon="mdi mdi-soccer" class="light intended"></v-icon>
                        </v-btn>
                    </div>
                    
                </Transition>
            </v-card>
        </v-container>
    </div>
</Transition>

<div v-if="showComplaint">
    <WriteComplaint :matchId=this.matchId @closePopUp="handleComplaint"/>
</div>

</template>


<script>
import WriteComplaint from './WriteComplaint.vue'

export default {
    props: ['matchId'],
    components: {
        WriteComplaint
    },
    data() {
        return {
            widthSetup: '',
            showSite: false,
            result: '',
            fullName: '',
            showIntro: false,
            showFullPlayerName: false,
            showNewGameButton: false,
            showComplaint: false
        }
    },
    methods: {
        resize(){
            this.widthSetup = '400px'
            if(this.$vuetify.display.xs){
                this.widthSetup = '100%'
            }
        },
        async getGameInfo(){
            try{
                const info = await this.$api.gameController.getGameInformation(this.matchId)
                this.fullName = info.playerFullName

                if(info.gameStatus === "LOST_BY_CANCELLATION"){
                    this.result = '- Aufgegeben'
                }else if(info.gameStatus === "LOST_BY_POINTS"){
                    this.result = '- keine Punkte'
                }else{
                    this.result = ''
                }
                //console.log(info)
            }catch(error){
                console.log('Error while fetching game info: ' + error)
            }
        },
        async showDetails(){
            await this.delay(500)
            this.showIntro = true
            await this.delay(1500)
            this.showFullPlayerName = true
            await this.delay(800)
            this.showNewGameButton = true
        },
        delay(milliseconds){
            return new Promise(resolve => {
                setTimeout(resolve, milliseconds);
            });
        },
        startGame(){
            this.$router.push({name: 'GameSetup'})
        },
        callComplaint(){
            this.showComplaint = true
        },
        handleComplaint(){
            this.showComplaint = false
        }
    },
    mounted() {
        this.resize()
        this.showSite = true
        this.getGameInfo()
        this.showDetails()
    }
}
</script>

<style scoped>
.text-h4 {
    line-height: 2.5rem !important;
}

.displayFullPlayerName-enter-from {
  opacity: 0;
}
.displayFullPlayerName-enter-active {
  transition: 2.0s ease all;
}
.showSite-enter-active {
        transition: all 1.0s ease;
}
.showSite-enter-from {
    opacity: 0;
    transform: translateY(100px);
}
</style>