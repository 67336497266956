export default class gameController {

    constructor(axios){
        this.axios = axios
    }

    getGlobalInformation() {
        //console.log('method called to get global information')
        return this.axios.get(`/legends/getGlobalInformation`).then(response => response.data)
    }

    start(data) {
        //console.log('method called to start game')
        return this.axios.post(`/legends/startNewGame`, data).then(response => response.data)
    }

    startNextRound(uid) {
        //console.log('requested next round')
        return this.axios.get(`/legends/startNextRound/${uid}`).then(response => response.data)
    }

    getCurrentPoints(uid) {
        //console.log('requested current points')
        return this.axios.get(`/legends/getCurrentPoints/${uid}`).then(response => response.data)
    }

    getHighScore(timeRange, matchIdToHighLight) {
        //console.log('called high score with timerange ' + timeRange + " and match id to highlight " + matchIdToHighLight)
        return this.axios.get(`/legends/getHighScore/${timeRange}/${matchIdToHighLight}`).then(response => response.data)
    }

    quitGame(uid) {
        //console.log('requested quit game')
        return this.axios.get(`/legends/quitGame/${uid}`)
    }

    getGameInformation(uid) {
        //console.log('requested game information')
        return this.axios.get(`/legends/getMatchInformation/${uid}`).then(response => response.data)
    }
}
