<template>
    <h1 class="highlightTitle" :class="titleClass">
        {{ highscores.selectionDescription }}
    </h1>

<Transition name="showSite">
        <v-container class="mt-0">
            <v-card 
                class="mx-auto pt-0 pl-2 pr-2 overflow-auto"
                :width="widthPage"      
                height="70vh"
                rounded="24"
                elevation="24"
                style="text-align: center"
                >
<!--
                <v-card-title class="highScoreTitle">
                    {{ highscores.selectionDescription }}
                </v-card-title>
-->
                <v-sheet>
                    <HighScoreBox :hsObj=highScoreHead class='heavy'/>
                    <div v-for="(singleHS, i) in highscores.highScores" :key="i">
                        <HighScoreBox :hsObj=singleHS />
                    </div>
                </v-sheet>
            </v-card>
        </v-container>
</Transition>

</template>


<script>
import HighScoreBox from './HighScoreBox.vue'

export default {
    props: ['matchId'],
    components: {
        HighScoreBox
    },
    data() {
        return {
            widthPage: '',
            isMobile: false,
            highScoreTypeId: 2,        
            allHighscores: [],
            highscores: [],
            highScoreHead: {
                ranking: 'Rang',
                contenderName: 'Spieler',
                points: 'Punkte',
                durationInSeconds: 'Zeit',
                playerName: 'Legenden'
            },
            titleClass: ''
        }
    },
    methods: {
        resize(){
            this.widthPage = '600px'
            this.titleClass = 'highlightTitleHs'
            if(this.$vuetify.display.xs){
                this.widthPage = '100%'
                this.titleClass = 'highlightTitleXs'
                this.isMobile = true
            }
        },
        async getHighScores() {
            try {
                let type = ''
                if(this.highScoreTypeId === 0){ type = 'month' }
                if(this.highScoreTypeId === 1){ type = 'monthlyWinner'}
                if(this.highScoreTypeId === 2){ type = 'week' }

                this.highScoreTypeId === 1 ? this.highScoreHead.ranking = 'Monat' : this.highScoreHead.ranking = 'Rang'
                    
                //type='all' //only for development and testing
                
                if(this.allHighscores[this.highScoreTypeId] === undefined){
                    this.allHighscores[this.highScoreTypeId] = await this.$api.gameController.getHighScore(type, this.matchId)
                }else{
                   // console.log('cached high score list')
                }
                this.highscores = this.allHighscores[this.highScoreTypeId]
                //.log("id: " + this.highScoreTypeId)
                //console.log(this.highscores)
            
                this.highScoreTypeId += 1
                if(this.highScoreTypeId === 3){ this.highScoreTypeId = 0}
            } catch(error) {
                console.error('Error while getting high score table')
            }
            setTimeout(this.getHighScores, 5000);
        }
    },
    mounted() {
        this.resize()
        this.getHighScores(this.highScoreTypeId)
    }
}

</script>

<style scoped>

i.v-icon.v-icon {
color: rgba(191, 108, 26);
} 
</style>