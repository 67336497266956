<template>
<v-container>
    <v-card
        class="mx-auto pa-2 welcomeContainer"
        rounded="24"
        elevation="24"
        :width="widthMatchpage"
        >

        <v-card-title>
            <span v-if="$vuetify.display.xs"><h1>Fussball Legenden Quiz</h1></span>
            <span v-if="$vuetify.display.smAndUp"><h1>Fussball Legenden Quiz</h1></span>
        </v-card-title>
        <p />

        <v-card-text class="lessPadding">
            Bei uns findest du über 200 der größten und erfolgreichsten Fussball-Legenden aller Zeiten!<br/>Egal ob aus Bundesliga, Premier League, Serie A oder den anderen Top Ligen Europas und der Welt - bei uns findest du alle.
            <br/><br/>Du kennst Pele, Maradona und Gerd Müller? Aber auch Thiago, Steven Gerrard und Emmanuel Adebayor?
            Dir reichen wenige Hinweise und du weißt um wen es geht? Dann probiere es direkt aus und setz dich an die Spitze der Rangliste!
        </v-card-text>

        <v-divider />
        <v-card-actions class="justify-center lessTopMarginForButton">
            <v-btn variant="flat" @click="startGame">
                Neues Spiel
                <v-icon start icon="mdi mdi-soccer" class="light intended"></v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
    
    <img :src="logoNeg" class="imageCenter" alt="legendenquiz logo">

</v-container>

</template>

<script setup>
    import logoNeg from '@/assets/logo-negativ.png'
</script>

<script>

export default {
    data() {
        return {
            widthMatchpage: '',
        }
    },
    methods: {
        resize(){
            this.widthMatchpage = '50%'
            if(this.$vuetify.display.xs){
                this.widthMatchpage = '100%'
            }
        },
        startGame(){
            this.$router.push({name: 'GameSetup'})
        }
    },
    mounted () {
        this.resize()
      //console.log("width: " + this.$vuetify.display.smAndUp)
    }
}
</script>

<style scoped>
    .text {
        line-height: 200%;
    }
    .welcomeContainer {
        text-align: center;
    }

    .imageCenter{
        display: block;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
        width: 15%;
        height: 15%;
    }
    .lessTopMarginForButton{
        margin-top: -10px !important;
    }
    .lessPadding{
        padding-top: 5px;
        padding-bottom: 10px;
    }
    .bori{
        text-align: right;
        vertical-align: bottom;
    }
</style>
