<template>
    <Teleport to="#app">
        <Transition name="modal-fade">
            <div class="modal-wrapper" v-if="showPopUp">
                <v-form @submit.prevent="closeModalOk">
                <v-card
                    class="mx-auto pa-0 mt-16"
                    :width="widthMatchpage"
                    rounded="24"
                    elevation="24"
                    >
                    <v-toolbar
                        color="success"
                        :title="headline"
                        >
                        <img :src="logoNeg" class="logoInPopup" alt="legendenquiz logo">
                    </v-toolbar>

                    <v-card-text>
                        <div class="pa-4" v-html="description" />
                    </v-card-text>

                    <v-text-field
                        v-show="showTextBox"
                        variant="solo-filled"
                        autofocus
                        v-model="text"
                        :rules="validationSatisfiedForText"
                        class="pl-6 pr-6"
                        />

                    <v-card-text class="noPadding">
                        <div class="small-text">{{ descriptionBelow }}</div>
                    </v-card-text>

                    <v-card-text :class="showMoreTextOption ? '' : 'hide'">
                        <a :class="showMoreText ? 'hide' : ''" class="small-text" @click="activateShowMoreText">Hinweise zur Tippabgabe</a>
                        <div :class="showMoreText ? '' : 'hide'" class="small-text text-on-left" v-html="moreText" />
                        <div :class="showMoreText ? '' : 'hide'" class="small-text" @click="deactivateShowMoreText">Hinweise ausblenden</div>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn
                            variant="flat" 
                            type="submit"
                            >
                            OK
                        </v-btn>
                        <v-btn
                            v-show="showCancelButton"
                            variant="flat" 
                            @click="closeModalCancel"
                            >
                            abbrechen
                        </v-btn>
                    </v-card-actions>
                </v-card>
                </v-form>
            </div>
        </Transition>
    </Teleport>
</template>

<script setup>
    import logoNeg from '@/assets/logo-negativ.png'
</script>

<script>
export default {
    props: ['headline', 'description', 'descriptionBelow', 'showTextBox', 'showCancelButton', 'moreText'],
    data() {
        return {
            showPopUp: false,
            text: '',
            textRequiredError: false,
            widthMatchpage: '',
            showMoreTextOption: false,
            showMoreText: false
        }
    },
    emits: {
        yes: null,
        no: null
    },
    methods: {
        resize(){
            this.widthMatchpage = '400px'
            if(this.$vuetify.display.xs){
                this.widthMatchpage = '85%'
            }
        },
        checkVariables(){
            if(this.moreText != '' && this.moreText != null && this.moreText != undefined){
                this.showMoreTextOption = true
            }
        },
        closeModalOk(){
            if(!this.showTextBox || (this.showTextBox && !this.textRequiredError && this.text.length != 0)) {
                //console.log("given text: " + this.text)
                this.$emit('yes', this.text)
            }
        },
        closeModalCancel(){
            this.$emit('no','')
        },
        activateShowMoreText(){
            this.showMoreText = true;
        },
        deactivateShowMoreText(){
            this.showMoreText = false;
        }
    },
    computed: {
        validationSatisfiedForText() {
            return [
                (v) => {
                    var tooShort = (v.length < 4 && v.length != 0) ? true : false
                    var tooLong = v.length > 300 ? true : false
                
                    if(tooShort) {
                        this.textRequiredError = true
                        return 'bitte gib mindestens 4 Zeichen ein'
                    }
                    if(tooLong) {
                        this.textRequiredError = true
                        return 'bitte höchstens 300 Zeichen'
                    }
                    this.textRequiredError = false
                    return true;
                }
            ];
        }
    },
    mounted() {
        this.resize()
        this.showPopUp = true
        this.checkVariables()
    }
}

</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);

  text-align: center;
}

.modal-fade-enter-from {
  opacity: 0;
}

.modal-fade-enter-active {
  transition: 0.5s ease all;
}

.logoInPopup{
    width: 85px;
    margin-right: -10px;
}

.small-text {
    font-size: 0.55rem;
}
.noPadding {
    padding: 0;
}
.text-on-left{
    text-align: left;
}
</style>