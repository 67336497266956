import HintController from "@/api/controller/hintController.js";
import JokerController from "@/api/controller/jokerController.js";
import GameController from "@/api/controller/gameController";
import GuessPlayerController from "@/api/controller/guessPlayerController";
import ComplaintController from "@/api/controller/complaintController";
import FeedbackController from "@/api/controller/feedbackController";
import axios from "axios";

export class API{

    constructor(){
        this.axios = axios
        this.axios.defaults.baseURL = 'https://be.legendenquiz.de';

        this.hintController = new HintController(this.axios)
        this.jokerController = new JokerController(this.axios)
        this.gameController = new GameController(this.axios)
        this.guessPlayerController = new GuessPlayerController(this.axios)
        this.complaintController = new ComplaintController(this.axios)
        this.feedbackController = new FeedbackController(this.axios)
    }

    install(app){
        app.config.globalProperties.$api = this
        
        app.provide('api', this)
    }
}

export function createAPI(){
    return new API()
}

export default new API()