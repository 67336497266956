<template>
    <div class='cd'>
        <Transition name="countdown">
            <span class='countdownNumber' v-if="show === 3">3</span>
            <span class='countdownNumber' v-else-if="show === 2">2</span>
            <span class='countdownNumber' v-else-if="show === 1">1</span>
            <span class='countdownNumber' v-else-if="show === 0">0</span>
        </Transition>
    </div>
</template>


<script>

    export default {
        data() {
            return {
                countdown: 3,
                show: 99
            }
        },
        methods: {
            delay(milliseconds){
                return new Promise(resolve => {
                    setTimeout(resolve, milliseconds);
                })
            },
            async startTimer(){
                let delay = 1000 //TODO: should be 1300 - only shorter for testing
                this.show = 3
                await this.delay(delay)
                this.show = 2
                await this.delay(delay)
                this.show = 1
                await this.delay(delay)
                //this.show = 0
                //console.log("start the game")
                this.$emit('countdownEnd')
                //this.startTimer()
            }
        },
        mounted() {
            this.startTimer();
        }
    }
 </script>

<style scoped>
.cd {
    margin-top: 10%;
    margin-left: -10%;
}
.countdownNumber {
    font-size: 1000%;
    color: #9ee1b3;
    font-weight: bold;
    position: absolute;
    opacity: 0;
}

.countdown-enter-active {
    opacity: 1.0;
    animation: bounce-in 0.8s;
}
.countdown-leave-active-should-not-be-shown {
    opacity: 1;
    animation: bounce-out 0.8s;
}
@keyframes bounce-in {
  0% { transform: scale(0); }
  100% { transform: scale(3); }
}
@keyframes bounce-out {
  0% { transform: scale(4); }
  100% { transform: scale(10); }
}
@media only screen and (max-width: 600px) {  
    .cd {
        margin-top: 40%;
        margin-left: -20%;
    }
}
 </style>